<template>
    <div class="share">
        <div class="header">
            <a-icon class="back" @click="$router.go(-1)" type="left" />
            <span class="title">{{ $t('share.title') }}</span>
        </div>
        <div class="box">
            <div class="bg"></div>
            <div class="avatar">
                <a-icon class="icon" type="user" />
            </div>
            <div class="address">
                {{$store.state.userinfo.address}}
            </div>
            <!-- <vue-qr :correctLevel="3" :autoColor="false" colorDark="#127afe" :text="shareUrl" :size="120" :margin="0" :logoMargin="3"></vue-qr> -->
            <div class="code">
                <vue-qr v-if="shareUrl" :correctLevel="3" :autoColor="false" :text="shareUrl" :size="200" :margin="10" :logoSrc="logoUrl" :logoMargin="5" />
            </div>
            <div>
                {{$t('share.longtap')}}
            </div>
            <div class="or">
                <span class="dash"></span>
                <span class="ortext">{{$t('share.or')}}</span>
                <span class="dash"></span>
            </div>
            <div>
                {{$t('share.copy')}}
            </div>
            <div class="copyurl">
                <div class="url">
                    {{shareUrl}}
                </div>
                <div class="copy" @click="copyLink()">
                    <a-icon type="copy" />
                </div>
            </div>
        </div>
    
    </div>
      
    </template>
    <script>
    import VueQr from 'vue-qr';
    import config from '../config'
    export default {
      name: "Share",
      data() {
        return {
          logoUrl: '/statics/imgs/logo_icon.png',
          shareUrl: config._BASE_URL+'register/'+this.$store.state.userinfo.address,
        };
      },
      components:{
          VueQr
      },
      mounted() {
        this.getData();
      },
      methods: {
        getData(){
            console.log(this.shareUrl)
        },
        copyLink(){
          let oInput = document.createElement('input');
          oInput.value = this.shareUrl;
          document.body.appendChild(oInput);
          oInput.select(); // 选择对象;
          console.log(oInput.value)
          document.execCommand("Copy"); // 执行浏览器复制命令
          this.$message.success(this.$t('common.copySuccess'));
          oInput.remove();
        },
      },
    };
    </script>
    <style lang="less" scoped>
    .share{
        padding: 70px 15px;
        width: 100%;
        .box {
            background: @white;
            border-radius: 15px;
            margin: 60px 15px;
            padding: 20px 20px 50px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #333;
            line-height: 40px;
            .avatar{
                background: @white;
                border-radius: 50%;
                position: relative;
                top: -60px;
                width: 80px;height: 80px;
                font-size: 40px;
                color: @primary-color;
                margin-bottom: -50px;
                padding: 10px;
            }
            .code{
                width: 200px;height:200px;
            }
            .or{
                line-height: 40px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                .dash{
                    margin-top: 20px;
                    flex: 1;
                    border-top: 1px dashed #dadada;
                }
                .ortext{
                    padding: 0 10px;
                }
            }
            .copyurl{
                width: 100%;
                display: flex;
                align-items: center;
                background: @primary-color-shadow;
                padding: 7px;
                border-radius: 7px;
                .url{
                    overflow: scroll;
                }
                .copy{
                    padding-left: 7px;
                }
            }
            
        }
        .sharenetworks{
            color: @white;
            .sharenetwork{
                color: @white;
                margin: 5px;
                padding: 0 5px;
                border-radius: 5px;
    
            }
        }
    }
    
    </style>
    